// src/components/Contact.js
import React from 'react';

const Siren = () => {
  return (
    <div className="page">
      <div>
        <div className="project-right">
          <h1>Text-to-ES</h1>
          <p>This demo plugin was created at the end of my internship at Siren. It encapsulates the best results in terms of schema linking and query generation. It uses BGE embeddings for the schema linking and GPT-4o for the query generation. The details and results of the research in this 6-month internship are currently being compiled and added to other works to be made into a research paper. It will be linked here when it is released.</p> <p>* Process of linking the natural language query of the user and the database, in order to reduce it to its relevant parts</p> <p>** Embeddings are mathematical vector representations of data, here text. BGE is a general embedding method created by the Beijing Academy of Artificial Intelligence</p>
        </div>
        <img src={process.env.PUBLIC_URL + '/images/project_images/siren_2.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/siren_1.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/siren_3.png'} alt="ping" />
      </div>
        
      </div>
  );
};

export default Siren;
