// src/components/Contact.js
import React from 'react';

const WrdCntr = () => {
  return (
    <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/project_images/ping_2.png'} alt="ping" />
          <img src={process.env.PUBLIC_URL + '/images/project_images/ping_1.png'} alt="ping" />
        </div>
        <div className="project-right">
          <h1>Word Counter</h1>
          <p>Some text goes here...</p>
        </div>
      </div>
  );
};

export default WrdCntr;
