// src/components/Contact.js
import React from 'react';

const WIP = () => {
  return (
    <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/project_images/WIP.png'} alt="ping" />
        </div>
        <div className="project-right">
          <h1>Other pages are on the way!</h1>
          <ul>
            <li><b>WordCounter</b>: A little program to help writers monitor their work, made in C++</li>
            <li><b>SCIA projects</b>: Some important projects I made in the AI major at EPITA about machine learning, reinforcement learning, deep learning, ...</li>
          </ul>
        </div>
      </div>
  );
};

export default WIP;
