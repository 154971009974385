// src/components/Contact.js
import React from 'react';


const Contact = () => {
  return (
    <div className="contact">
      <h2>Contact Me</h2>
      <div className="contact-item">
        <img src={process.env.PUBLIC_URL + '/images/mail.png'} alt="Email Icon" style={{ width: '4em', height: '4em'}} />
        <a href="mailto:poelger.jonathan@gmail.com">poelger.jonathan@gmail.com</a>
      </div>
      <div className="contact-item">
        <img src={process.env.PUBLIC_URL + '/images/linkedin.png'} alt="LinkedIn Icon" style={{ width: '4em', height: '4em'}} />
        <a href="https://www.linkedin.com/in/poelger-jonathan/" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/poelger-jonathan/</a>
      </div>
      <div className="contact-item">
        <img src={process.env.PUBLIC_URL + '/images/CV.png'} alt="CV Icon" style={{ width: '4em', height: '4em'}} />
        <a href={process.env.PUBLIC_URL + '/images/CV Jonathan Poelger.pdf'} download>Download CV</a>
      </div>
    </div>
  );
};

export default Contact;
