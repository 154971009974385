import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const images = [
    // add a local image
    [process.env.PUBLIC_URL + 'images/Tristan.png', "/TristanNamour"],
    [process.env.PUBLIC_URL + 'images/tombez.png', "/TombezMonsieur"], //
    [process.env.PUBLIC_URL + 'images/siren.png', "/Siren"], //
    [process.env.PUBLIC_URL + 'images/Tiger.png', "/Tiger"], //
    [process.env.PUBLIC_URL + 'images/Ping.png', "/Ping"],  //
    [process.env.PUBLIC_URL + 'images/42sh.png', "/42sh"],  //
    [process.env.PUBLIC_URL + 'images/project_images/WIP.png', "/WIP"],  //
   // [process.env.PUBLIC_URL + 'https://via.placeholder.com/200', "/WordCounter"],
  ];

  return (
    <div className="grid">
      {images.map((src, index) => (

        <Link className='column' key={index} to={"/projects" + src[1]}>
          <img 
            src={src[0]}
            alt={`{index + 1}`} 
          />
        </Link>
      ))}
    </div>
  );
};

export default Home;
