// src/components/Contact.js
import React from 'react';

const Tiger = () => {
  return (
    <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/Epita.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/Tiger.png'} alt="ping" />
        </div>
        <div className="project-right">
          <h1>Tiger</h1>
          <p>This school project was made by a team of 4 students. Its goal was to rebuild from scratch, in c++ a compiler for a simple language called Tiger.
            Click <a href="https://assignments.lrde.epita.fr">[HERE]</a> to see the complete description of the project.
          </p>
          <p><b>Scanner and Parser</b><br/>At the end of this stage, the compiler can scan and parse Tiger programs and detect lexical and syntaxical errors</p>
          <p><b>Building the Abstract Syntax Tree</b><br/>At the end of this stage, the compiler can build abstract syntax trees of Tiger programs and pretty-print them.</p>
          <p><b> Bindings</b><br/>At the end of this stage, the compiler must be able to compute and display the bindings. These features are triggered by the options -b/--bindings-compute, --object-bindings-compute and -B/--bindings-display.</p>
          <p><b>Type Checking</b><br/>At the end of this stage, the compiler type checks Tiger programs, and annotates the AST. Clear error messages are required.</p>
          <p><b>Translating to the High Level Intermediate Representation</b><br/>At the end of this stage the compiler translates the AST into the high level intermediate representation, HIR for short.</p>
          <p><b>Translating to the Low Level Intermediate Representation</b><br/>At the end of this stage, the compiler produces low level intermediate representation: LIR. LIR is a subset of the HIR: some patterns are forbidden. This is why it is also named canonicalization.</p>
          <p><b>Instruction Selection</b><br/>At the end of this stage, the compiler produces a very low level intermediate representation: ASSEM. This language is basically the target assembly, enhanced with an unlimited number of registers ($x666).</p>
          <p><b>Liveness Analysis</b><br/>At the end of this stage, the compiler computes the input of TC-9: the interference graph (or conflict graph).</p>
          <p><b>Register Allocation</b><br/>At the end of this stage, the compiler produces code that is runnable using Nolimips.</p>
        </div>
      </div>
  );
};

export default Tiger;
