import React from 'react';
import './projects.css';

const Ping = () => {
    return (
        <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/project_images/ping_2.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/ping_1.png'} alt="ping" />
        </div>
        <div className="project-right">
          <h1>Ping, the wacky IDE</h1>
          <p>Ping is an IDE made using Electron, in Javascript, by 4 students.
            It was designed to have a lot of features, some of them being very wacky and weird, like the overall design.</p>
          <p>Here is the "official" introduction as it was given to us at the begining of the project:</p>
          <p>"MyIDE 3.0 is an IDE designed for developers with a forward-looking vision. With the technology of artificial intelligence hosted in the blockchain cloud as smart contracts, MyIDE 3.0 is ahead of the market, your code, and even your developer. Monitor the performance of your collaborators for potential substantial rewards based on their efficiency. To be prepared for the Web 3.0 world, you need 3.0 investors and 3.0 developers, with a salary 3.0 and especially an IDE 3.0!"</p>

          <h2>Features</h2>
          <ul>
            <li>Code editor</li>
            <ul>
              <li>Code highlighting</li>
              <li>Code completion</li>
              <li>File Explorer (save, read, load...)</li>  
              <li>Code execution in a terminal</li>
            </ul>
            <li>Theme</li>
            <ul>
              <li>Dark mode</li>
              <li>Light mode</li>
              <li>Forced transition between the mode every minute with bright flashing red screens</li>
            </ul>
            <li>Other features</li>
            <ul>
              <li>Netiquette automatic corretion for tickets</li>
              <li>Git integration (basic commands GUI)</li>
              <li>LiveShare: coding on the same file online with another user in real time</li>
              <li>Surveillance API</li>
            </ul>
        </ul>
    <h2>Surveillance API</h2>
    <p>The intern monitoring server is available at the address https://surveillance.des.stagiaires.yakux-technologies.2v.pm</p>
    <p>It has 3 endpoints:</p>
    <ul>
        <li>POST `/stat/exit` for exit 1 on npm scripts</li>
        <p>Body:</p>
        <pre>
                "name": "intern"
        </pre>
        <p>where "intern" is the name of the user concerned (e.g., the name of their session).</p>
        <p>For security reasons, your request must have a header `token-super-secret` with a value of `touloused`.</p>

        <li>POST `/stat/lignes` for each new line written. Same behavior as `/stat/exit`.</li>

        <li>POST `/stat/netiquette` for each netiquette fail. Same behavior as `/stat/exit`.</li>
    </ul>
        </div>
      </div>
    )
}

export default Ping;
