// src/components/Contact.js
import React from 'react';

const Tristan = () => {
  return (
    <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/project_images/Tristan(4).png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/Tristan(2).png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/Tristan(3).png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/Tristan(1).png'} alt="ping" />
          
        </div>
        <div className="project-right">
          <h1>Tristan Namour et la princesse</h1>
          <p>Tristan Namour is a 2D platformer game made with Unity in one week. It consists of 4 levels, each ending with a boss fight, and containing story bits in the form of visual novel-like dialogues. Most of the assets are from the Unity marketplace, and some of them are handmade. All the code and integration was made by me, and the idea, design and story was made by @Cindelfe and me.</p>
          <h2>Features</h2>
          <ul>
            <li>4 levels (a tutorial and 3 longer levels) each with their ambiance, music and main mechanics</li>
            <li>3 boss fights (the last one with 2 very different phases)</li>
            <li>Visual novel-like dialogues for the storytelling</li>
            <li>The character is fully animated (idle, run, jump, fall, fall against a wall, attack, hurt, die, and one "emote")</li>
            <li>7 different enemies (outside bosses) with their own mechanics</li>
            <li>Different traps and special plateforms (spikes, bouncing, disapearing...) </li>
            <li>Possibility to choose any levels or go straight to the bosses of each level in the menu</li>
            <li>All the text and dialogues are in french</li>
          </ul>

          <h2>Levels</h2>
          <ul>
            <li>Tutorial: <b>Le village del Yntro</b></li>
              <ul>
                <li>Introduction dialogues at the begining and the end of the level</li>
                <li>Basic mechanics are shown to the player</li>
                <li>First enemy encounter (green slimes that wander around)</li>
                <li>First health item after the enemies</li>
              </ul> <br/>           
            <li>Level 1: <b>Les plaines d'Hynulle</b></li>
              <ul>
                <li> The level is set outside with a combinaison of simple jumps parts and fight parts</li>
                <li>Introduction to 3 new enemies
                  <ul>
                    <li>Red slimes that follow the player</li>
                    <li>Frogs that jump around</li>
                    <li>Eagles that go higher in the sky before dashing towards the player</li>
                  </ul>
                </li>
                <li>First traps (spikes and pitfalls)</li>
                <li>First boss fight (A man that you angered on the way). He runs towards the player and unleashes one of 3 attacks (long spear, fast sword, huge greatsword)</li>
              </ul> <br/> 
            <li>Level 2: <b>Les mines del Amoria</b></li>
              <ul>
                <li> The level is set inside a cavern with mostly jumps that involve evading traps, and some ennemies on the way</li>
                <li>Elevator at the end with multiple outcomes, but only one doesn't lead to a certain death</li>
              <li>Introduction to 2 new enemies
                  <ul>
                    <li>Gargoyles that fly and go towards the player and do a spin attack</li>
                    <li>Mushroom golems that walk towards the player and do a bite attack</li>
                  </ul>
                  </li>
                <li>New traps (disappearing platforms, moving spike balls)</li>
                <li>Second boss fight: a giant that leaps or runs towards the player depending on how far he is, and swings his sword if near enough</li>
              </ul>  <br/>
            <li>Level 3: <b>Le chateau des Jasauvet</b></li>
              <ul>
              <li> The level in set in the sky with a lot of possibilities to fall down, strong ennemies and bouncy jumps</li>
                <li>Introduction to a new enemy: A skeleton that goes towards the player swinging its sword</li>
                <li>Introduction to bouncing platforms</li>
                <li>The boss fight possesses 2 parts: 
                  <ul>
                    <li>First part: An armored general doing fast attacks that can come from the sky or slide on the whole available ground</li>
                    <li>Second part: The general removes her armor and fights by spawning gargoyles and running towards the player, initiating powerful counterattack if hit.</li>
                  </ul>
                </li>
              </ul>  
          </ul>
        </div>
      </div>
  );
};

export default Tristan;
