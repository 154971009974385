import React from 'react';
import { Chrono } from 'react-chrono';
import './Skills.css';

const Skills = () => {
  const items = [
    {
      title: "Sep 2019",
      cardTitle: "Entered EPITA",
      cardSubtitle: "Started in the intergrated preparatory classes at EPITA.",
      cardDetailedText: "During one and a half year, I did mostly Mathematics, Physics, Computer Science and English. " +
      "During this time I notably learned OcamL, C, C# and Python. I also learned about the basics of computer science and how to use Linux."
    },
    {
      title: "Jan 2021 - Jun 2021",
      cardTitle: "Academic exchange in South Korea",
      cardSubtitle: "Exchange semester at Seoul National University of Science and Technology (SeoulTech)",
      cardDetailedText: "The semester exchange was a great opportunity to discover a new culture and broaden my horizons" +
      "I followed classes of C, Java, OS, Network and Korean culture."
    },
    {
      title: "Sep 2021",
      cardTitle: "Entered EPITA's engineering cycle",
      cardSubtitle: "Started the engineering cycle at EPITA",
      cardDetailedText: "This year focused on very intensive programming courses in C, C++, Java and JavaScrpit," +
      "while continuing Math, English and more theorical computer science courses."
    },
    {
        title: "Sep 2022 - Jan 2023",
        cardTitle: "Internship at ITS Group",
        cardSubtitle: "R&D Intern: Deep learning for image recognition",
        cardDetailedText: "Research and development in artificial intelligence: Detection, recognition,"+
        " and automatic inventory of datacenter equipment. The internship also involved units tests on a kernel " + 
        "module and Javascript frontend as side missions."
      },
      {
        title: "Jan 2023",
        cardTitle: "Entered EPITA's IA major",
        cardSubtitle: "Entered the 'SCIA' major (Cognitive Sciences and Artificial Intelligence)",
        cardDetailedText: "This year was focused on learing AI, Machine Learning, Deep Learning, NLP, " +
        "Data Science, and the Mathematics relevant to this field, as well as building a multitude of AI projects on very different subjects."
      },
      {
        title: "Feb 2024 - Aug 2024",
        cardTitle: "Internship at Siren",
        cardSubtitle: "R&D Intern: LLM and AI",
        cardDetailedText: "Research and development on LLMs for information retrieval from knowledge graphs and natural language processing. " 
        + "The internship also involved dataset adaptation and creation, and an integration in Siren's dashboard."
      },
      /*{
        title: "",
        cardTitle: "",
        cardSubtitle: "",
        cardDetailedText: ""
      },*/
    // Add more items here
  ];

  return (
    <div className="skills">
      <div className="timeline">
        <Chrono
          items={items}
          disableToolbar
          mode="VERTICAL_ALTERNATING"
          theme={{ primary: '#444', secondary: 'white' }}
        />
      </div>
    </div>
  );
};

export default Skills;
