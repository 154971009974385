// src/components/Contact.js
import React from 'react';

const TmbMsr = () => {
  return (
    <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/project_images/tombez_menu.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/tombez_0.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/tombez_1.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/tombez_2.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/tombez_2_1.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/project_images/tombez_3.png'} alt="ping" />
        </div>
        <div className="project-right">
          <h1>Tombez Monsieur</h1>
          <p>"Tombez Monsieur" is a platform game inspired by Fall Guys consisting of 3 levels in addition to the tutorial. The goal is to finish each level with the best time.</p>
          <p>It was developed in one week with Unity by a team of 4 students who never used Unity before.</p>
    <h2>Controls:</h2>
    <ul>
        <li>Move (arrow keys or ZQSD or WASD)</li>
        <li>Jump (space bar)</li>
        <li>Move only united objects (hold the left click next to the object)</li>
        <li>Climb on flower walls (hold space in front of the wall)</li>
        <li>Pause a level (Enter)</li>
    </ul>
    
    <h2>Levels:</h2>
    Each level has a different theme and an easter egg (the location of the easter eggs can be found after the credits):
    <ul>
    <li>Tutorial level: serves as an introduction to the game</li>
    <li>Level 1: jumping course with disappearing platforms (falling into the void makes them reappear)</li>
    <li>Level 2: dark maze playing with light</li>
    <li>Level 3: obstacle course with object movement to build a staircase Additionally, each level is timed. The time is displayed in the end level menu.</li>
    </ul>
    <h2>Game Systems and Mechanics:</h2>
    <ul>
        <li>Rotating platforms</li>
        <li>Moving platforms, obstacles, and pendulums</li>
        <li>Checkpoints in case of falling</li>
        <li>Moving lasers sending the player back to the last visited checkpoint</li>
        <li>Music on each level and on the menu</li>
        <li>Use of particles for torches in level 1</li>
        <li>Use of handmade shaders for level 2</li>
        <li>Use of multiple lights mainly on level 2 (headlamp, flashing lights, lasers, colored lights, etc.)</li>
        <li>Use of materials on all objects/walls in the game</li>
        <li>Use of a graphic raycaster for menus</li>
        <li>Creation of a home menu, pause menu, and end level menu in 2D</li>
        <li>Use of an animator with several animations for the lights in level 2</li>
        <li>Management of 5 different scenes with transition GUIs</li>
    </ul>

    <h2>Credits:</h2>
    <ul>
        <li>Developers:
            <ul>
                <li>Magali JOMIER</li>
                <li>Thibault SACCON</li>
                <li>Antony ROUVE</li>
                <li>Jonathan POELGER</li>
            </ul>
        </li>
        <li>Music:
            <ul>
                <li>"MX067_57 - Girl From Petaluma-13858" by Megatrax (elevator music)</li>
                <li>"Map" by Mutato Muzika (Crash Bandicoot 1 Theme)</li>
                <li>"Skyrim Theme" by Taylor Davis (Skyrim Theme Instrumental)</li>
                <li>"Everybody Falls" by Jukio Kallio & Daniel Hagström (Fall Guys Theme)</li>
                <li>Street sounds of Hadath recorded by Anthony Ghazel</li>
            </ul>
        </li>
        <li>Tool: Unity</li>
    </ul>

    <h2>Easter Eggs:</h2>
    <ul>
        <li>Tutorial: Bring the yellow block to the last green platform and place it on the right to reach an alternative ending at the end of the lava ramp.</li>
        <li>Level 1:
On the starting platform, jump on the torch hanging on the left wall to pass over the door without opening it.</li>

<li>Level 2:
Upon entering the second part of the level (the small maze), follow the right wall until you reach a dead end. The right wall at the end of the dead end is an illusion and can be crossed to reach a faster alternative ending.</li>

<li>Level 3:
Place the smallest yellow block on the ground at your starting position (opposite the green wall). Use it to jump higher to trigger an alternative ending.</li>
      </ul>
        </div>
      </div>

  );
};

export default TmbMsr;
