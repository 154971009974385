// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';

import Qdsh from './components/Projects/42sh';
import Siren from './components/Projects/Siren';
import Tiger from './components/Projects/Tiger';
import TmbMsr from './components/Projects/TombezMonsieur';
import Tristan from './components/Projects/Tristan';
import WrdCntr from './components/Projects/WordCounter';
import Ping from './components/Projects/Ping';
import WIP from './components/Projects/WIP';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/experience" element={<Skills />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects/TristanNamour" element={<Tristan />} />
          <Route path="/projects/42sh" element={<Qdsh />} />
          <Route path="/projects/TombezMonsieur" element={<TmbMsr />} />
          <Route path="/projects/Tiger" element={<Tiger />} />
          <Route path="/projects/WordCounter" element={<WrdCntr />} />
          <Route path="/projects/Siren" element={<Siren />} />
          <Route path="/projects/Ping" element={<Ping />} />
          <Route path="/projects/WIP" element={<WIP />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
