// src/components/Contact.js
import React from 'react';

const Qdsh = () => {
  return (
    <div className="page">
        <div className="project-left">
        <img src={process.env.PUBLIC_URL + '/images/Epita.png'} alt="ping" />
        <img src={process.env.PUBLIC_URL + '/images/42sh.png'} alt="ping" />
        </div>
        <div className="project-right">
          <h1>42sh</h1>
          <p>This school project was completed by a team of 4 individuals with the goal of developing a new Linux shell using only native C, without the use of any libraries.</p>
          <p>The project includes features such as lexing, parsing, basic Linux commands, shell script execution, and full support for the bash language. There was a strict policy against any kind of memory leaks, unclean code, or inefficiencies for the evaluation.</p>
          <p>Unfortunately, due to the project's academic nature, the code is not available for viewing, which is a common practice for many programming projects. While there may not be much visual content to showcase, the project was deemed successful!</p>
        </div>
      </div>
  );
};

export default Qdsh;
