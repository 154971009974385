import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <header className="header">
      <button className="menu-button" onClick={toggleMenu}>☰</button>
      <div ref={menuRef} className={`menu-dock ${menuOpen ? 'open' : ''}`}>
        
        <ul className="menu">
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/projects" onClick={toggleMenu}>Projects</Link></li>
          <li><Link to="/experience" onClick={toggleMenu}>Experience & Education</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
        </ul>

        <div className="contact">
          <p>Contact Me</p>
          <div className="contact-item">
            <img src={process.env.PUBLIC_URL + '/images/mail.png'} alt="Email Icon" style={{ width: '2em', height: '2em'}} />
            <a href="mailto:poelger.jonathan@gmail.com">poelger.jonathan@gmail.com</a>
          </div>
          <div className="contact-item">
            <img src={process.env.PUBLIC_URL + '/images/linkedin.png'} alt="LinkedIn Icon" style={{ width: '2em', height: '2em'}} />
            <a href="https://www.linkedin.com/in/poelger-jonathan/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
          <div className="contact-item">
            <img src={process.env.PUBLIC_URL + '/images/CV.png'} alt="CV Icon" style={{ width: '2em', height: '2em'}} />
            <a href={process.env.PUBLIC_URL + '/images/CV Jonathan Poelger.pdf'} download>Download CV</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
