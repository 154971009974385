// src/components/Contact.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">

      <div className="about-section">
        <img src={process.env.PUBLIC_URL + 'images/picture.jpg'} alt="Description" className="about-left" />
        <div className="about-right">
        <p><h2>Who am I?</h2>
          Hi! My name is Jonathan, I'm 22 and I'm about to finish my 5th year or engineering school at EPITA. 
          I've been passionated about AI for a long time now, discovering it via very interesting evolutionary
          AIs learing to do complex tasks from scratch. The explosion of models and possibilities in the recent
          years arrived right in time for me, when I could actually learn how they work and how to build, train,
          and/or use them. I also have ideas and projects to make videogames, as making games is a dream of mine,
          and that was actually what made me interested in programming in the first place.</p>
        </div>
      </div>

      <div className="about-section">
      <img src={process.env.PUBLIC_URL + 'images/passions.png'} alt="Description" className="about-right" />
        <div className="about-left">
        <p><h2>My passions</h2>
          In my free time I like pretty nerdy stuff, I spend a lot of time playing videogames, watching anime
            and reading manga, my favorite ones being Hollow Knight, Steins;Gate and the Fate series. I love story-focused
            games like the ones by Quantic Dream and Don't Nod, indie games like Undertale or Iconoclasts, as well as games I can casually play with my friends
            like League of Legends. Speaking of my friends, I actually really like board and card games, and If I could
            I'd really like to hike on a more regular basis with them.</p>
        </div>
        
      </div>

      <div className="about-section">
        <img src={process.env.PUBLIC_URL + 'images/skills.png'} alt="Description" className="about-left" />
        <div className="about-right">
        <p><h2>My skills</h2>
          With school and personal projects combined I had the opportunity to use quite a lot of languages and technologies. 
            I have a solid knowledge of several language, including <span class="highlight">Python</span>, mostly for data analysis
            and AI (Torch/Keras/Tensorflow/...), <span class="highlight">C++ </span> and <span class="highlight">C</span>, in which
            I made a lot of school projects, as well as <span class="highlight">Java</span> and <span class="highlight">C#
            </span>. I also have a good knowledge of <span class="highlight">JavaScript</span> / <span class="highlight">HTML
            </span> / <span class="highlight">CSS</span> for web development. I am very familiar with Linux and Git, and I have some
            bases with <span class="highlight">Scala</span>, <span class="highlight">Spark</span> and <span class="highlight">SQL
            </span>. Lastly, I have some experience with <span class="highlight">Unity</span> and <span class="highlight">Godot
            </span> for game development.</p>
        </div>
      </div>

    </div>
    
    
    /*
    <div className="about">
      <div className="column">
        <img src={process.env.PUBLIC_URL + '/images/picture.jpg'} alt="Description" className="column-image" />
        <p className="column-text">Quick presentation <br/>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus eget nunc ultricies
          fermentum. Nullam nec purus eget nunc ultricies fermentum. Nullam nec purus eget nunc ultricies
        </p>
      </div>
      <div className="column">
        <h2 className="column-title">My passions</h2>
        <ul className="column-list" >
          <li>Videogames:
            <ul>
              <li>I mostly play Solo games or games with my friends. My favourite videogames of all time are ..., ... and ... !</li>
            </ul>
          </li>
          <li>Anime & Manga
            <ul>
              <li>I watch a lot of anime and read a lot of manga. My favourite anime of all time is ... !</li>
            </ul>
          </li>
          <li>Music</li>
          <li>Bla bla</li>
        </ul>
      </div>
      <div className="column">
        <h2 className="column-title">My Skills</h2>
        <p className="column-text">Text related to the second title.</p>
      </div>
    </div>*/
  );
};

export default About;
