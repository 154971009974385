import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {

  return (
    <div className="grid">
     
      <Link to={"/projects"}>
        <div className="column">
            <img 
              src={process.env.PUBLIC_URL + '/images/2.png'}
              alt={`{index + 1}`} 
            />
            <h2 className="column-title">PROJECTS</h2>
        </div>
      </Link>
        
      
      <Link to={"/experience"}>
        <div className="column">
            <img 
              src={process.env.PUBLIC_URL + '/images/1.png'}
              alt={`{index + 1}`} 
            />
          <h2 className="column-title">EXPERIENCES</h2>
        </div>
      </Link>
        
      
      <Link to={"/about"}>
      <div className="column">
          <img 
            src={process.env.PUBLIC_URL + '/images/3.png'}
            alt={`{index + 1}`} 
          />
          <h2 className="column-title">ABOUT ME</h2>
        </div>
      </Link>
    </div>
  );
};

export default Home;
